import React from 'react';

import Typography from 'components/atoms/typography';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Molecule Quote
 * 
 * Quote
 */
function Quote (props) {
  const {
    className = "",
    quote = "",
    author = "",
    authorRole = "",
    authorCompany = "",
    children,
    ...other
  } = props;

  return <div
    className={`${css["molecule__quote-container"]} ${className}`}
    {...other}
  >
    <Typography className={css["quote"]}>{quote} ”</Typography>
    <div className={css["separator"]}/>
    <div className={css["author-content"]}>
      <Typography className={css["author"]}>
        {author}&nbsp;/&nbsp;
      </Typography>
      <Typography className={css["author-role"]}>
        {authorRole}&nbsp;-&nbsp;
      </Typography>
      <Typography className={css["author-company"]}>
        {authorCompany}
      </Typography>
    </div>
  </div>;
}

export default Quote;
