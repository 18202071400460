/* eslint-disable max-len */
import { list } from 'data/cases';

export const Info = {
  title: "**Somos**\n\nUMA\n\nCONTENT\n\nCO",
  video: 'https://www.youtube.com/embed/7C5EzPWiAfA',
  image: "/images/pictures/InfoHome.png",
  alt: 'Somos uma Content Co',
};

export const Description = {
  subtitle: "Sobre a Eyxo",
  variant: "dark",
  description: "Criamos, produzimos e distribuímos conteúdo estratégico para marcas alcançarem seus resultados. \n\nNosso diferencial? \n\nFazemos isso totalmente conectados ao que há de mais atual, com o propósito de transformar o mercado de comunicação e gerar impacto positivo na sociedade.",
  button: "saiba mais",
  buttonUrl: "/about",
  buttonTextColor: "gray",
};

export const Cases = {
  title: 'Cases',
  description: 'Sem formato, por princípio. Assim buscamos que sejam as nossas produções: ousadas, diversas e cheias de propósito. Conheça algumas delas.',
  list
};

export const Clients = {
  list: [
    { title: 'Tembici', iconName: 'Tembici', alt: 'Tembici', url: 'https://www.tembici.com.br/' },
    { title: 'Americanas', iconName: 'Americanas', alt: 'Americanas', url: 'https://www.americanas.com.br/' },
    { title: 'TikTok', iconName: 'Tiktok', alt: 'TikTok', url: 'https://www.tiktok.com/pt-BR' },
    { title: '99', iconName: 'Svg99', alt: '99', url: 'https://99app.com' },
    { title: 'Next', iconName: 'Next', alt: 'Next', url: 'https://next.me' },
    { title: 'Banco24Horas', iconName: 'Bank24H', alt: 'Banco 24Horas', url: 'https://www.banco24horas.com.br' },
    { title: 'Pier', iconName: 'Pier', alt: 'Pier', url: 'https://www.pier.digital' },
    { title: 'Panvel', iconName: 'Panvel', alt: 'Panvel', url: 'https://www.panvel.com/' },
  ]
};

export const Quotes = {
  title: "O que dizem da Eyxo",
  list: [
    {
      author: 'Cleber Paradela',
      authorRole: 'Diretor de Marketing Solutions',
      authorCompany: '99',
      quote: 'A Eyxo é uma empresa que considero de fato uma verdadeira parceira. Estão sempre conosco, quase como fazendo parte do mesmo time. São estratégicos, criativos e extremamente ágeis, além de fazerem da estratégia à execução da produção totalmente inhouse. Sempre tenho o prazer de recomendar.'
    },
    {
      author: 'Lara Thomazini',
      authorRole: 'Director of Brand Maketing',
      authorCompany: 'Tembici Brasil',
      quote: 'A Eyxo é realmente uma parceira e parece que é parte do time. Mergulhou nos desafios da Tembici e aprende junto com a gente todos os dias para criar as comunicações mais efetivas e encantadoras possíveis.'
    },
    {
      author: 'Thaís Passarella',
      authorRole: 'Head of Marketing, Brand & PR',
      authorCompany: 'Banco24Horas',
      quote: 'O time da eyxo é descomplicado, presente e com ótimas ideias. Parceiros para novas ideias e para por na rua o que precisamos!'
    },
  ]
};