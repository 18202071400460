import React from 'react';
import { Carousel } from 'react-responsive-carousel';

import { Quote as QuoteIcon } from 'components/icons';
import Abstract from 'components/atoms/abstract';
import Title from 'components/atoms/title';
import Button from 'components/atoms/button';
import Quote from 'components/molecules/quote';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Organism SectionQuote
 * 
 * Section containing a list of quotes
 */
function SectionQuote (props) {
  const {
    className = "",
    list = [],
    title = "",
    children,
    ...other
  } = props;

  return <div
    className={`${css["organism__section-quote-container"]} ${className}`}
    {...other}
  >
    <div
      className={css["content"]}
      data-content-wrapper="true"
    >
      <Title level="3" color="lightgray" className={css["title"]}>
        {title}
      </Title>

      <div className={css["list-container"]}>
        <QuoteIcon className={css["icon"]}/>

        <Carousel
          className={css["carousel"]}
          infiniteLoop
          autoPlay={true}
          interval={10000}
          showThumbs={false}
          showStatus={false}
          swipeable={false}
          renderArrowPrev={(clickHandler, hasPrev) =>
            hasPrev && (
              <Button
                arrow="left"
                className={css["btn-prev"]}
                alt="Anterior"
                onClick={clickHandler}
              />
            )
          }
          renderArrowNext={(clickHandler, hasNext) =>
            hasNext && (
              <Button
                className={css["btn-next"]}
                alt="Próximo"
                onClick={clickHandler}
              />
            )
          }
          showIndicators={false}
        >

          {
            list.length > 0 &&
            list.map((quote, index) => (
              <Quote key={index} {...quote} />
            ))
          }
        </Carousel>
      </div>

    </div>
    <Abstract type="4" className={css["abstract-circles-grid"]}/>
    <Abstract type="8" className={css["abstract-grid-vertical"]}/>
    {children}
  </div>;
}

export default SectionQuote;
