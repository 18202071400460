import React, { useState } from 'react';
import Link from 'next/link';
import ReactMarkdown from 'react-markdown';

import * as Icons from 'components/icons';

import Title from 'components/atoms/title';
import Typography from 'components/atoms/typography';
import Button from 'components/atoms/button';
import Modal from 'components/molecules/modal';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Organism SectionDescription
 * 
 * Component containing page description
 */
function SectionDescription (props) {
  const {
    className = "",
    containerClassName = "",
    titleContentClassName = "",
    id = null,
    keyTitle = "",
    background = "gray", // dark, gray, white
    subtitle = "",
    textColor = "lightgray", // (dark/lightgray/white) // textColor
    variant = "dark", // if subtitle contains background or not (transparent/dark/white/gray)
    description = "",
    list = [], // [{ description: '' }]
    button = "",
    buttonUrl = "",
    buttonVariant = "dark",  // (dark/gray/white) // background
    buttonTextColor = "white", // (dark/gray/white) // textColor
    buttonPosition = "bottom",     // if button is in the +right or bottom default: bottom
    iconName = "",
    showModal = false,
    modalDescription,
    modalIconName,
    children,
    ...other
  } = props;

  const Icon = Icons[iconName];
  const ModalIcon = Icons[modalIconName];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpenCloseModal = () => setIsModalOpen(oldState => !oldState);

  const handleOnClick = (e) => {
    if(id && showModal && modalDescription) {
      e.preventDefault();
      document.getElementById(id).scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center"
      });
      handleOpenCloseModal();
    }
  };

  return <div
    className={`${css["organism__section-description-container"]} ${className}`}
    id={id}
    data-bg={background}
    {...other}
  >
    <div
      className={`${css["content"]} ${containerClassName}`}
      data-content-wrapper="true"
    >
      <div className={css["info"]}>
        <div className={`${css["title-content"]} ${titleContentClassName}`}>
          {subtitle && <Title
            key={keyTitle}
            level="3"
            color={textColor}
            className={css["title"]}
            data-variant={variant}
            data-position={buttonPosition}
          >{subtitle}</Title>}
        </div>
        <div className={css["info-content"]}
          data-position={buttonPosition}
        >
          <div className={css["desc"]}>
            {Icon &&
              <Icon
                className={css[iconName === "Company" ? "icon" : "icon-text"]}
              />
            }
            {description &&
              <Typography>
                <ReactMarkdown>{description}</ReactMarkdown>
              </Typography>
            }
            {
              list.length > 0 && (<ul data-bg={background}>
                {
                  list.map(({description}, index) => (
                    <li key={index}>
                      <Typography >{description}</Typography>
                    </li>
                  ))
                }
              </ul>)
            }
          </div>
          {button && buttonUrl && buttonPosition === "right" && (
            <Link href={buttonUrl} passHref>
              <a
                title={button}
                className={css["button"]}
              >
                <Button
                  rounded
                  theme={buttonVariant}
                  parentTheme={background}
                  textColor={buttonTextColor}
                  alt={button}
                  onClick={handleOnClick}
                >
                  {button}
                </Button>
              </a>
            </Link>
          )}
        </div>
        {button && buttonUrl && buttonPosition === "bottom" && (
          <Link href={buttonUrl} passHref>
            <a
              title={button}
              className={css["button-bottom"]}
            >
              <Button
                rounded
                theme={buttonVariant}
                parentTheme={background}
                textColor={buttonTextColor}
                alt={button}
                onClick={handleOnClick}
              >
                {button}
              </Button>
            </a>
          </Link>
        )}
      </div>
    </div>
    {children}

    {
      isModalOpen &&
      <Modal onClick={handleOpenCloseModal} className={css["modal"]}>
        {ModalIcon && <ModalIcon className={css["modal-icon"]} />}
        <Typography>{modalDescription}</Typography>
      </Modal>
    }
  </div>;
}

export default SectionDescription;
