import React, { useEffect } from 'react';

import { pageView } from 'utils/metrics';
import { PageProvider } from 'providers/page';

import Abstract from 'components/atoms/abstract';

import TitleHead from 'components/atoms/title-head';
import SectionInfo from 'components/organisms/section-info';
import SectionDescription from 'components/organisms/section-description';
import SectionCases from 'components/organisms/section-cases';
import SectionClients from 'components/organisms/section-clients';
import SectionQuote from 'components/organisms/section-quote';

import {
  Info,
  Description,
  Cases,
  Clients,
  Quotes
} from 'data/home';

import css from './styles.module.scss';

function Index (props) {
  useEffect(() => {
    pageView();
  }, []);

  return (<>
    <div className={css["page__homeContainer"]}>
      <TitleHead />
      <SectionInfo {...Info}>
      </SectionInfo>
      <SectionDescription
        {...Description}
        className={css["desc"]}
        containerClassName={css["desc-container"]}
        titleContentClassName={css["title-content"]}
      >
        <Abstract
          type="4"
          className={css["desc-circles"]}
          transform={90}
          speed={100}
        />
        <Abstract
          type="3"
          className={css["desc-circles-group"]}
          transform={90}
          speed={30}
        />
      </SectionDescription>
      <SectionCases {...Cases}/>
      <SectionClients {...Clients}/>
      <SectionQuote {...Quotes}/>
    </div>
  </>);
}

export default function IndexPage (props) {
  const { children, ...other } = props;
  return <PageProvider><Index {...other} />{children}</PageProvider>;
}
